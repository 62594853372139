import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadDailyTask, createDaily } from "../../redux/actions/daily";
import { loadSkins } from "../../redux/actions/skin";
import { loadMines } from "../../redux/actions/mine";

interface IDaily {
  daily_task: IDailyTask;
  loading: boolean;
}

interface IDailyTask {
  date: string;
  type: string;
  skin_id: string;
  amount: number;
  cipher: number;
  cipher_bonus: number;
  combo: string;
  combo_bonus: number;
}

const initialState = {
  date: "",
  type: "",
  skin_id: "",
  amount: 0,
  cipher: 0,
  cipher_bonus: 0,
  combo: "",
  combo_bonus: 0,
};

const DailyTask: any = ({
  isAuthenticated,
  daily: { daily_task, loading },
  createDaily,
  loadDailyTask,
  loadSkins,
  skins,
  loadMines,
  mines,
}: {
  isAuthenticated: boolean;
  createDaily: any;
  loadDailyTask: any;
  daily: IDaily;
  loadSkins: any;
  loadMines: any;
  skins: any;
  mines: any;
}) => {
  const [formData, setFormData] = useState(initialState);

  const navigate = useNavigate();

  useEffect(() => {
    if (!daily_task) loadDailyTask();
    if (!skins) loadSkins();
    if (!mines) loadMines();

    if (!loading && daily_task) {
      const configData = { ...initialState };

      const formattedEndDate = new Date(daily_task.date)
        .toISOString()
        .slice(0, 10);

      configData.date = formattedEndDate;
      configData.type = daily_task.type;
      configData.skin_id = daily_task.skin_id;
      configData.cipher = daily_task.cipher;
      configData.combo = daily_task.combo;
      configData.amount = daily_task.amount;
      configData.cipher_bonus = daily_task.cipher_bonus;
      configData.combo_bonus = daily_task.combo_bonus;

      setFormData(configData);
    }
  }, [loading, daily_task, loadDailyTask, mines, skins, loadSkins, loadMines]);

  const {
    date,
    type,
    skin_id,
    amount,
    cipher,
    cipher_bonus,
    combo,
    combo_bonus,
  } = formData;

  const onChange = (e: any) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const formData = {
      date,
      type,
      skin_id,
      amount,
      cipher,
      cipher_bonus,
      combo,
      combo_bonus,
    };
    createDaily(formData).then((res: boolean) => {
      if (res) navigate("/dashboard");
    });
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let mineSelect;
  if (mines && mines.length > 0)
    mineSelect = mines.map((mine: any, idx: number) => (
      <option key={idx} value={mine._id}>
        {mine.title}
      </option>
    ));

  let skinSelect;
  if (skins && skins.length > 0)
    skinSelect = skins.map((skin: any, idx: number) => (
      <option key={idx} value={skin._id}>
        {skin.title}
      </option>
    ));

  return (
    <section className="container">
      <h1 className="large text-primary">Daily Task Configuration</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Reset daily settings
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="date"
            placeholder="End Date"
            name="date"
            value={date || ""}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Type; skin or prize amount"
            name="type"
            value={type}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <select name="skin_id" value={skin_id} onChange={onChange}>
            <option>Choose Skin</option>
            {skinSelect}
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Prize Amount"
            name="amount"
            value={amount}
            onChange={onChange}
            min={0}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Cipher Code"
            name="cipher"
            value={cipher}
            onChange={onChange}
            min={0}
            maxLength={5}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Cipher Bonus"
            name="cipher_bonus"
            value={cipher_bonus}
            onChange={onChange}
            min={0}
          />
        </div>
        <div className="form-group">
          <select name="combo" value={combo} onChange={onChange}>
            <option>Choose Mine ID</option>
            {mineSelect}
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Combo Bonus"
            name="combo_bonus"
            value={combo_bonus}
            onChange={onChange}
            min={0}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Edit" />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </section>
  );
};

DailyTask.propTypes = {
  createDaily: PropTypes.func.isRequired,
  loadDailyTask: PropTypes.func.isRequired,
  loadMines: PropTypes.func.isRequired,
  loadSkins: PropTypes.func.isRequired,
  daily: PropTypes.object.isRequired,
  skins: PropTypes.array,
  mines: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  daily: state.daily,
  skins: state.skin.skins,
  mines: state.mine.mines,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  loadDailyTask,
  loadMines,
  loadSkins,
  createDaily,
})(DailyTask);
