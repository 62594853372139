import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  createAirdropConfig,
  loadAirdropConfig,
  shareTokens,
} from "../../redux/actions/airdrop";

interface IAirdrop {
  config: IConfig;
  loading: boolean;
}

interface IConfig {
  endDate: string;
  prize: number;
  tokenPerFriend: number;
  tokenPerTask: number;
  tokenPerBalance: number;
}

const initialState = {
  endDate: "",
  amount: 0,
  ppf: 0,
  ppt: 0,
  ppb: 0,
};

const Airdrop: any = ({
  isAuthenticated,
  airdrop: { config, loading },
  createAirdropConfig,
  loadAirdropConfig,
  shareTokens,
}: {
  isAuthenticated: boolean;
  createAirdropConfig: any;
  shareTokens: any;
  loadAirdropConfig: any;
  airdrop: IAirdrop;
}) => {
  const [formData, setFormData] = useState(initialState);

  const navigate = useNavigate();

  useEffect(() => {
    if (!config) loadAirdropConfig();

    if (!loading && config) {
      const configData = { ...initialState };

      const formattedEndDate = new Date(config.endDate)
        .toISOString()
        .slice(0, 16);
      configData.endDate = formattedEndDate;
      configData.amount = config.prize;
      configData.ppf = config.tokenPerFriend;
      configData.ppt = config.tokenPerTask;
      configData.ppb = config.tokenPerBalance;

      setFormData(configData);
    }
  }, [loading, config, loadAirdropConfig]);

  const { endDate, amount, ppf, ppt, ppb } = formData;

  const onChange = (e: any) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e: any) => {
    e.preventDefault();

    createAirdropConfig(endDate, amount, ppf, ppt, ppb).then((res: boolean) => {
      if (res) navigate("/dashboard");
    });
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Airdrop Configuration</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Reset end date and prize amount
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={onSubmit} noValidate>
        <div className="form-group">
          <input
            type="datetime-local"
            placeholder="End Date"
            name="endDate"
            value={endDate || ""}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Prize Amount"
            name="amount"
            value={amount}
            onChange={onChange}
            min={0}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Price Per Friend"
            name="ppf"
            value={ppf}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Price Per Task"
            name="ppt"
            value={ppt}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Price Per Balance"
            name="ppb"
            value={ppb}
            onChange={onChange}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Edit" />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
      <button
        className="btn btn-primary"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          shareTokens();
          navigate("/dashboard");
        }}
      >
        Start Airdrop
      </button>
    </section>
  );
};

Airdrop.propTypes = {
  createAirdropConfig: PropTypes.func.isRequired,
  loadAirdropConfig: PropTypes.func.isRequired,
  shareTokens: PropTypes.func.isRequired,
  airdrop: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  airdrop: state.airdrop,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  shareTokens,
  loadAirdropConfig,
  createAirdropConfig,
})(Airdrop);
