import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createTask } from "../../redux/actions/task";

interface ICreateTask {
  createTask: any;
  isAuthenticated: boolean;
}

const AddTask: any = ({ createTask, isAuthenticated }: ICreateTask) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    description: "",
    price: 0,
    link: "",
  });

  const { title, type, description, price, link } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    createTask(formData).then(() => navigate("/tasks"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Create a task</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Create a task to prompt your social
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter the title"
            name="title"
            value={title}
            onChange={onChange}
            minLength={6}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter the type"
            name="type"
            value={type}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Enter the price"
            name="price"
            value={price}
            onChange={onChange}
            minLength={6}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter the link"
            name="link"
            value={link}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <textarea
            placeholder="Enter the description"
            name="description"
            value={description}
            onChange={onChange}
            minLength={6}
            rows={5}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Add Tip" />
        <Link className="btn btn-light my-1" to="/tasks">
          Go Back
        </Link>
      </form>
    </section>
  );
};

AddTask.propTypes = {
  createTask: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createTask })(AddTask);
