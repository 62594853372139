import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMine } from "../../redux/actions/mine";

interface ICreateMine {
  createMine: any;
  isAuthenticated: boolean;
}

const AddMine: any = ({ createMine, isAuthenticated }: ICreateMine) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    initial_price: 0,
    initial_profit: 0,
    times: 0,
  });

  const { title, initial_price, initial_profit, times } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    createMine(formData).then(() => navigate("/mines"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Create a mine item</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Create a mine item to raise profit
        per hour
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter the title"
            name="title"
            value={title}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Enter the initial price"
            name="initial_price"
            min={0}
            value={initial_price}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Enter the initial profit"
            name="initial_profit"
            value={initial_profit}
            min={0}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Enter the times"
            name="times"
            value={times}
            min={0}
            onChange={onChange}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Add Tip" />
        <Link className="btn btn-light my-1" to="/mines">
          Go Back
        </Link>
      </form>
    </section>
  );
};

AddMine.propTypes = {
  createMine: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createMine })(AddMine);
