import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMaskot } from "../../redux/actions/maskot";
import { setAlert } from "../../redux/actions/alert";

interface ICreateSkin {
  createMaskot: any;
  isAuthenticated: boolean;
}

const AddMaskot: any = ({ createMaskot, isAuthenticated }: ICreateSkin) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    target: 0,
  });
  const [file, setFile] = useState<File | null>(null);

  const { title, description, target } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (!files || files?.length === 0) return;

    setFile(files[0]);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!file) {
      setAlert("File is not uploaded", "danger");
      return;
    }

    const formData_ = new FormData();
    formData_.append("title", title);
    formData_.append("target", String(target));
    formData_.append("description", description);
    formData_.append("file", file);

    createMaskot(formData_).then(() => navigate("/maskots"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Create a Maskot</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Create a maskot to attract people
      </p>
      <small>* = required field</small>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter the title"
            name="title"
            value={title}
            onChange={onChange}
            minLength={6}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Enter the price"
            name="target"
            value={target}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <textarea
            placeholder="Enter the description"
            name="description"
            value={description}
            onChange={onChange}
            minLength={6}
            rows={5}
          />
        </div>
        <div className="form-group">
          <input type="file" name="file" onChange={handleFileUpload} />
        </div>
        <input type="submit" className="btn btn-primary" value="Add Maskot" />
        <Link className="btn btn-light my-1" to="/maskots">
          Go Back
        </Link>
      </form>
    </section>
  );
};

AddMaskot.propTypes = {
  createMaskot: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createMaskot })(AddMaskot);
