import React, { Fragment, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadMaskots, deleteMaskot } from "../../redux/actions/maskot";

interface IMaskotList {
  loadMaskots: any;
  deleteMaskot: any;
  maskots: any;
  loading: boolean;
  isAuthenticated: boolean;
}

const MaskotList: any = ({
  loadMaskots,
  deleteMaskot,
  maskots,
  loading,
  isAuthenticated,
}: IMaskotList) => {
  useEffect(() => {
    loadMaskots();
  }, [loadMaskots]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let maskotContent;
  if (!loading && maskots && maskots.length > 0)
    maskotContent = maskots.map((_: any, idx: number) => (
      <tr key={_._id}>
        <td>{idx + 1}</td>
        <td>{_.title}</td>
        <td>{_.description}</td>
        <td>{_.target}</td>
        <td>{_.image.name}</td>
        <td>{_.created_at}</td>
        <td>
          <button
            onClick={() => deleteMaskot(String(_._id))}
            className="btn btn-danger"
          >
            Delete
          </button>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Maskot List</h1>
        <Link to="/create-maskot" className="btn btn-light">
          <i className="fab fa-black-tie text-primary" /> Add Maskot
        </Link>
        {maskots && maskots.length > 0 ? (
          <table className="table" style={{ marginTop: 5 }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Target</th>
                <th>Image</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{maskotContent}</tbody>
          </table>
        ) : (
          <p>No Maskots</p>
        )}
      </section>
    </Fragment>
  );
};

MaskotList.propTypes = {
  loadMaskots: PropTypes.func.isRequired,
  deleteMaskot: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maskots: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.maskot.loading,
  maskots: state.maskot.maskots,
});

export default connect(mapStateToProps, { loadMaskots, deleteMaskot })(
  MaskotList
);
