import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../redux/actions/auth";

interface INavbar {
  auth: IAuth;
  logout: any;
}

interface IAuth {
  isAuthenticated: boolean;
}

const Navbar: any = ({ auth: { isAuthenticated }, logout }: INavbar) => {
  const authLinks = (
    <ul>
      <li>
        <Link to="/settings">Settings</Link>
      </li>
      <li>
        <Link to="/daily">Daily</Link>
      </li>
      <li>
        <Link to="/tasks">Tasks</Link>
      </li>
      <li>
        <Link to="/mines">Mines</Link>
      </li>
      <li>
        <Link to="/maskots">Maskots</Link>
      </li>
      <li>
        <Link to="/skins">Skins</Link>
      </li>
      <li>
        <Link to="/airdrop">Airdrop</Link>
      </li>
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />{" "}
          <span className="">Dashboard</span>
        </Link>
      </li>
      <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt" />{" "}
          <span className="">Logout</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  return (
    <nav className="navbar bg-dark">
      <h1>
        <Link to="/">
          <i className="fas fa-code" /> Honey Bear Boot
        </Link>
      </h1>
      <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
