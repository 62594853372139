import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Load Components
// import PrivateRoute from "./components/routing/PrivateRoute";
import Landing from "./components/layouts/Landing";
import Dashboard from "./components/dashboard/Dashboard";
import Navbar from "./components/layouts/Navbar";
import Alert from "./components/layouts/Alert";
import Login from "./components/auth/Login";
import Airdrop from "./components/airdrop/Airdrop";
import TaskList from "./components/task/TaskList";
import AddTask from "./components/task/AddTask";
import MaskotList from "./components/maskot/MaskotList";
import SkinList from "./components/skin/SkinList";
import AddSkin from "./components/skin/AddSkin";
import AddMaskot from "./components/maskot/AddMaskot";
import MineList from "./components/Mine/MineList";
import DailyTask from "./components/daily/DailyTask";

// Import Style
import "./App.css";

// Redux
import store from "./redux/store";

// Utils
import setAuthToken from "./utils/setAuthToken";

// Types
import { loadUser } from "./redux/actions/auth";
import { LOGOUT } from "./redux/types";
import AddMine from "./components/Mine/AddMine";

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Router>
      <Navbar />
      <Alert />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/tasks" element={<TaskList />} />
        <Route path="/create-task" element={<AddTask />} />
        <Route path="/maskots" element={<MaskotList />} />
        <Route path="/create-maskot" element={<AddMaskot />} />
        <Route path="/skins" element={<SkinList />} />
        <Route path="/create-skin" element={<AddSkin />} />
        <Route path="/mines" element={<MineList />} />
        <Route path="/create-mine" element={<AddMine />} />
        <Route path="/daily" element={<DailyTask />} />
      </Routes>
    </Router>
  );
};

export default App;
