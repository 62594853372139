import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

interface IDashboard {
  auth: IAuth;
}

interface IAuth {
  isAuthenticated: boolean;
}

const Dashboard: any = ({ auth: { isAuthenticated } }: IDashboard) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Dashboard</h1>
      <p className="lead">
        <i className="fas fa-user" /> Welcome Admin
      </p>
    </section>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Dashboard);
