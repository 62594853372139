import React, { Fragment, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadTasks, deleteTask } from "../../redux/actions/task";

interface ITaskList {
  loadTasks: any;
  deleteTask: any;
  tasks: any;
  loading: boolean;
  isAuthenticated: boolean;
}

const TaskList: any = ({
  loadTasks,
  deleteTask,
  tasks,
  loading,
  isAuthenticated,
}: ITaskList) => {
  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let taskContent;
  if (!loading && tasks && tasks.length > 0)
    taskContent = tasks.map((_: any, idx: number) => (
      <tr key={_._id}>
        <td>{idx + 1}</td>
        <td>{_.title}</td>
        <td>{_.type}</td>
        <td>{_.description}</td>
        <td>{_.price}</td>
        <td>{_.link}</td>
        <td>{_.created_at}</td>
        <td>
          <button
            onClick={() => deleteTask(String(_._id))}
            className="btn btn-danger"
          >
            Delete
          </button>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Task List</h1>
        <Link to="/create-task" className="btn btn-light">
          <i className="fab fa-black-tie text-primary" /> Add Task
        </Link>
        {tasks && tasks.length > 0 ? (
          <table className="table" style={{ marginTop: 5 }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Type</th>
                <th>Description</th>
                <th>Price</th>
                <th>Link</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{taskContent}</tbody>
          </table>
        ) : (
          <p>No Tasks</p>
        )}
      </section>
    </Fragment>
  );
};

TaskList.propTypes = {
  loadTasks: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.task.loading,
  tasks: state.task.tasks,
});

export default connect(mapStateToProps, { loadTasks, deleteTask })(TaskList);
