import React, { Fragment, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadMines, deleteMine } from "../../redux/actions/mine";

interface IMineList {
  loadMines: any;
  deleteMine: any;
  mines: any;
  loading: boolean;
  isAuthenticated: boolean;
}

const MineList: any = ({
  loadMines,
  deleteMine,
  mines,
  loading,
  isAuthenticated,
}: IMineList) => {
  useEffect(() => {
    loadMines();
  }, [loadMines]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let mineContent;
  if (!loading && mines && mines.length > 0)
    mineContent = mines.map((_: any, idx: number) => (
      <tr key={_._id}>
        <td>{idx + 1}</td>
        <td>{_.title}</td>
        <td>{_.initial_price}</td>
        <td>{_.initial_profit}</td>
        <td>{_.times}</td>
        <td>{_.created_at}</td>
        <td>
          <button
            onClick={() => deleteMine(String(_._id))}
            className="btn btn-danger"
          >
            Delete
          </button>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Mine List</h1>
        <Link to="/create-mine" className="btn btn-light">
          <i className="fab fa-black-tie text-primary" /> Add Mine
        </Link>
        {mines && mines.length > 0 ? (
          <table className="table" style={{ marginTop: 5 }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Initial Price</th>
                <th>Initial Profit</th>
                <th>Times</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{mineContent}</tbody>
          </table>
        ) : (
          <p>No Mines</p>
        )}
      </section>
    </Fragment>
  );
};

MineList.propTypes = {
  loadMines: PropTypes.func.isRequired,
  deleteMine: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mines: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.mine.loading,
  mines: state.mine.mines,
});

export default connect(mapStateToProps, { loadMines, deleteMine })(MineList);
