import React, { Fragment, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadSkins, deleteSkin } from "../../redux/actions/skin";

interface ISkinList {
  loadSkins: any;
  deleteSkin: any;
  skins: any;
  loading: boolean;
  isAuthenticated: boolean;
}

const SkinList: any = ({
  loadSkins,
  deleteSkin,
  skins,
  loading,
  isAuthenticated,
}: ISkinList) => {
  useEffect(() => {
    loadSkins();
  }, [loadSkins]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let skinContent;
  if (!loading && skins && skins.length > 0)
    skinContent = skins.map((_: any, idx: number) => (
      <tr key={_._id}>
        <td>{idx + 1}</td>
        <td>{_.title}</td>
        <td>{_.description}</td>
        <td>{_.price}</td>
        <td>{_.image.name}</td>
        <td>{_.created_at}</td>
        <td>
          <button
            onClick={() => deleteSkin(String(_._id))}
            className="btn btn-danger"
          >
            Delete
          </button>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Skin List</h1>
        <Link to="/create-skin" className="btn btn-light">
          <i className="fab fa-black-tie text-primary" /> Add Skin
        </Link>
        {skins && skins.length > 0 ? (
          <table className="table" style={{ marginTop: 5 }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price</th>
                <th>Image</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{skinContent}</tbody>
          </table>
        ) : (
          <p>No Skins</p>
        )}
      </section>
    </Fragment>
  );
};

SkinList.propTypes = {
  loadSkins: PropTypes.func.isRequired,
  deleteSkin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  skins: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.skin.loading,
  skins: state.skin.skins,
});

export default connect(mapStateToProps, { loadSkins, deleteSkin })(SkinList);
